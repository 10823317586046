
import { useI18n } from "vue-i18n";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Privacy",
  setup() {
    const { t } = useI18n({ useScope: "global" });
    return { t };
  },
});
