
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import Agreement from "@/components/legal/Agreement.vue";
import Privacy from "@/components/legal/Privacy.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

export default defineComponent({
  name: "Legal",
  props: {
    page: { type: String, default: "0" },
  },
  setup(props) {
    const selectPage = ref(Number(props.page));
    const { t } = useI18n({ useScope: "global" });
    return { t, selectPage };
  },
  components: {
    Agreement,
    Privacy,
    TabView,
    TabPanel,
  },
});
